import React, { useState, useEffect } from "react"
import "./Styles/reset.css"
import "./App.sass"
import "./Styles/Media.sass"
import "./Styles/Global.sass"
// import SlotsData from "./SlotsData.json"

const PeopleInput = (props) => {
	// console.log(props)
	return (
		<section className="people">
			<label>
				<p>Nom</p>
				<input type="text" name="lastname" value={props.lastname} data-id={props.id} onChange={props.onChange} />
			</label>
			<label>
				<p>Prénom</p>
				<input type="text" name="firstname" value={props.firstname} data-id={props.id} onChange={props.onChange} />
			</label>
			<button type="button" className="delete" onClick={props.onDelete}>
				<span></span>
			</button>
		</section>
	)
}

function App() {
	const currentDay = new Date()
	const [slots, setSlots] = useState([])
	const [selectedSlot, setSelectedSlot] = useState(null)
	const [contact, setContact] = useState({ email: "", phone: "" })
	const [people, setPeople] = useState([{ id: 1, lastname: "", firstname: "" }])
	const [nbPeople, setNbPeople] = useState(0)
	const [success, setSuccess] = useState(false)
	const [errors, setErrors] = useState({ email: false, phone: false, selectedSlot: false, people: false })

	// Set selected slot
	const handleClick = (slot, time) => {
		// console.log("selectedSlot", { ...slot, ...time })
		setSelectedSlot({ ...slot, ...time })
	}

	// Set contact informations (email, phone)
	const handleChange = (input) => {
		// console.log("contact", { ...contact, [input.name]: input.value })
		setErrors({ ...errors, [input.name]: input.value === "" })
		setContact((prev) => {
			return { ...prev, [input.name]: input.value }
		})
	}

	// Update people name
	const handlePeople = (input) => {
		setPeople(
			people.map((elem) => {
				if (elem.id === parseInt(input.dataset.id)) {
					return { ...elem, [input.name]: input.value }
				}
				return elem
			})
		)
	}

	// Add new people
	const addPeople = () => {
		const maxId = Math.max(...people.map((user) => user.id))
		const newPeople = { id: maxId + 1, lastname: "", firstname: "" }
		setPeople((prev) => {
			return [...prev, newPeople]
		})
	}

	// Delete people
	const deletePeople = (deletedPerson) => {
		setPeople(
			people.filter((item) => {
				return item.id !== parseInt(deletedPerson)
			})
		)
	}

	const handleSubmit = () => {
		const filteredPeople = people.filter((item) => {
			return item.lastname.trim() !== "" && item.firstname.trim() !== ""
		})
		if (contact.email === "" || contact.phone === "" || selectedSlot === null || filteredPeople.length === 0) {
			console.log("Error", {
				email: contact.email === "",
				phone: contact.phone === "",
				selectedSlot: selectedSlot === null,
				people: filteredPeople.length === 0,
			})
			setErrors({
				email: contact.email === "",
				phone: contact.phone === "",
				selectedSlot: selectedSlot === null,
				people: filteredPeople.length === 0,
			})
			return false
		}
		setErrors({ email: false, phone: false, selectedSlot: false, people: false })
		const data = { contact, filteredPeople, selectedSlot }
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify(data),
		}
		fetch(`${process.env.REACT_APP_API_URL}Post.php`, requestOptions)
			.then((response) => response.json())
			.then((res) => {
				setSuccess(true)
				setSelectedSlot(null)
				setPeople([{ id: 1, lastname: "", firstname: "" }])
				setNbPeople(0)
			})
	}

	const handleReset = () => {
		setContact({ email: "", phone: "" })
		setSuccess(false)
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		})
	}

	useEffect(() => {
		const requestOptions = {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		}
		fetch(`${process.env.REACT_APP_API_URL}GetSlots.php`, requestOptions)
			.then((response) => response.json())
			.then((res) => {
				setSlots(res)
			})
	}, [success])

	useEffect(() => {
		setNbPeople(
			people.filter((item) => {
				return item.lastname.trim() !== "" && item.firstname.trim() !== ""
			}).length
		)
	}, [people])

	return (
		<div className="App">
			<header>
				<h1>À la mémoire de Samphan</h1>
				{/* <h2>Pour celles et ceux qui souhaitent lui rendre un hommage.</h2>
				<p className="subtitle">Pour des raisons sanitaires, nous ne pouvons accueillir plus de 7 personnes en même temps.</p> */}
			</header>
			<section className="container thanks">
				<p>Bonjour,</p>
				<p>Nous souhaitons vous adresser tous nos remerciements pour votre confiance et toute notre gratitude pour le soutien que vous nous avez apporté tout au long de cette épreuve.</p>
				<p>
					Il est encore possible de se recueillir devant l'autel consacré à Samphan qui est installé au cabinet. Si tel est votre souhait, merci de nous informer de votre venue en prenant
					contact avec Marie au <a href="tel:+33674837062">06.74.83.70.62</a>, Rémy au <a href="tel:+33760910007">07.60.91.00.07</a> ou Lucie au <a href="tel:+33666467992">06.66.46.79.92</a>.
				</p>
				<p>L'équipe Body And Soul : Marie, Rémy et Lucie.</p>
			</section>
			{/* <section className="container form-wrapper">
				<article>
					<h3>Informations de contact</h3>
					<p className="subtext">
						Un email de confirmation vous sera envoyé après votre inscription. Ces informations ne serviront qu’à vous joindre en cas de besoin. Merci de renseigner tous les champs.
					</p>
					<form
						method="post"
						className="form"
						onSubmit={() => {
							return false
						}}>
						<section className="contact">
							<label className={`${errors.email ? "error" : ""}`}>
								<p>Email</p>
								<input type="email" name="email" value={contact.email} onChange={(e) => handleChange(e.target)} />
							</label>
							<label className={`${errors.phone ? "error" : ""}`}>
								<p>N° de téléphone</p>
								<input type="phone" name="phone" value={contact.phone} onChange={(e) => handleChange(e.target)} />
							</label>
						</section>
						{people.map((i, key) => (
							<PeopleInput key={key} onChange={(e) => handlePeople(e.target)} onDelete={() => deletePeople(i.id)} {...i} />
						))}
						{people.length < 7 && (
							<button type="button" className="add" onClick={() => addPeople(2)}>
								<span></span> Ajouter une personne
							</button>
						)}
						<p className="notice">⚠️&nbsp;&nbsp;Seules les personnes dont le nom ET le prénom sont renseignés seront prises en compte.</p>
					</form>
				</article>
				<article className="location">
					<h3>Lieu du recueillement</h3>
					<div className="address">
						<h4>Cabinet Body & Soul</h4>
						<p>
							<a
								href="https://www.google.fr/maps/place/31+Rue+Denis+Papin,+94200+Ivry-sur-Seine/@48.8132719,2.392483,17z/data=!3m1!4b1!4m5!3m4!1s0x47e673b2e46bf5bf:0xe2e2166d8224ac8c!8m2!3d48.8132684!4d2.3946717?hl=fr"
								target="_blank"
								rel="noreferrer">
								31 rue Denis Papin
							</a>
						</p>
						<p>94200 Ivry-sur-Seine</p>
					</div>
				</article>
			</section>
			<section className="container slots">
				<h3>Choix du créneau</h3>
				<p className="subtext">Afin que tout le monde puisse se recueillir, seuls des créneaux de 20 minutes sont disponibles.</p>
				<div className="slots-inner">
					{slots.map((slot, index) => (
						<div className={`day-slots ${slot.date < currentDay.getDate() ? "pasted" : ""}`} key={index}>
							<p className="date">
								{slot.day} {slot.date}
								<span>{slot.moment}</span>
							</p>
							<ul>
								{slot.slot.map((time, key) => (
									<li
										key={time.id}
										onClick={() => handleClick(slot, time)}
										className={`${selectedSlot?.id === time.id ? "active" : null} ${time.available_slot === 0 ? "out" : time.available_slot < nbPeople ? "warning" : "available"} ${
											new Date(time.timestamp).getTime() < new Date().getTime() ? "pasted" : ""
										}`}>
										<p className="hour">
											{time.hour}:{String(time.minute).padStart(2, "0")}
										</p>
										<span className="places">({time.available_slot} places restantes)</span>
									</li>
								))}
							</ul>
						</div>
					))}

					<div className="legends">
						<ul>
							<li>
								<span className="available"></span>
								<p>Créneau disponible {nbPeople > 0 && (nbPeople > 1 ? `pour ${nbPeople} personnes` : `pour ${nbPeople} personne`)}</p>
							</li>
							<li>
								<span className="warning"></span>
								<p>Pas assez de place restante</p>
							</li>
							<li>
								<span className="out"></span>
								<p>Créneau indisponible</p>
							</li>
						</ul>
					</div>
				</div>
			</section>
			{!success ? (
				<section className="container resume">
					<h3>Récapitulatif</h3>
					<div className="inner">
						<div className="datas">
							<article>
								<p className="small-title">Informations de contact</p>
								<ul>
									{contact.email ? <li>{contact.email}</li> : <li className={`empty ${errors.email ? "error" : ""}`}>Email non renseigné</li>}
									{contact.phone ? <li>{contact.phone}</li> : <li className={`empty ${errors.phone ? "error" : ""}`}>N° de téléphone non renseigné</li>}
								</ul>
							</article>
							<article>
								<p className={`small-title ${errors.people ? "error" : ""}`}>
									{nbPeople} {nbPeople > 1 ? "personnes présentes" : "personne présente"}
								</p>
								<ul>
									{people
										.filter((item) => {
											return item.lastname !== "" && item.firstname !== ""
										})
										.map((person, key) => (
											<li key={key}>
												{person.lastname} {person.firstname}
											</li>
										))}
								</ul>
							</article>
							<article>
								<p className="small-title">Créneau choisi</p>
								<ul>
									{selectedSlot ? (
										<>
											<li>{`${selectedSlot.day} ${selectedSlot.date} mai`}</li>
											<li>à {`${selectedSlot.hour}h${String(selectedSlot.minute).padStart(2, "0")}`}</li>
										</>
									) : (
										<li className={`empty ${errors.selectedSlot ? "error" : ""}`}>Veuillez choisir votre créneau</li>
									)}
								</ul>
							</article>
						</div>
						<button className="btn confirm" onClick={() => handleSubmit()}>
							Confirmer
						</button>
					</div>
				</section>
			) : (
				<section className="container confirmation">
					<h3>Votre inscription a bien été prise en compte</h3>
					<p>Un email de confirmation vient de vous être envoyé à l'adresse suivante :</p>
					<p>{contact.email}</p>
					<button className="btn confirm" onClick={() => handleReset()}>
						Retour
					</button>
				</section>
			)} */}
		</div>
	)
}

export default App
